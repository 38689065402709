import React, { Component } from "react";
import GraphiQL from "graphiql";
import GraphiQLExplorer from "graphiql-explorer";
import {buildClientSchema, getIntrospectionQuery, isEnumType, isWrappingType, parse} from "graphql";
import { window, document, exists } from 'browser-monads';
import Responsive from '@bit/semantic-org.semantic-ui-react.responsive'


import "graphiql/graphiql.css";
// import "./material.css";
import "./explorer.css";

let schemaUrl = "";
let requestHeaders;
let defaultQueries;
var pageId;

function fetcher(params) {
    // alert("headers: \n" + JSON.stringify(requestHeaders, null, 2))
    console.log("pageId: " + pageId)
    if(pageId != 'hackernews') {
        requestHeaders = {};
    } else {
        requestHeaders = {
            'x-api-key': 'da2-p77mocnshzbszjfzhhwmsia5yu'
        }
    }
    const headers = Object.assign({
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }, requestHeaders)
    console.log("headers: \n" + JSON.stringify(headers, null, 2))
    return fetch(
        schemaUrl,
        {
            method: "POST",
            headers: headers,
            body: JSON.stringify(params)
        }
    )
        .then(function(response) {
            return response.text();
        })
        .then(function(responseBody) {
            try {
                return JSON.parse(responseBody);
            } catch (e) {
                return responseBody;
            }
        });
}

class GraphqlDashboard extends Component {
    _graphiql;
    state = {schema: null, query: this.props.defaultQueries, explorerIsOpen: true};

    componentDidMount() {
        fetcher({
            query: getIntrospectionQuery()
        }).then(result => {
            const editor = this._graphiql.getQueryEditor();
            editor.setOption("extraKeys", {
                ...(editor.options.extraKeys || {}),
                "Shift-Alt-LeftClick": this._handleInspectOperation
            });

            this.setState({schema: buildClientSchema(result.data)});
        });
    }

    _handleInspectOperation = (
        cm,
        mousePos
    ) => {
        const parsedQuery = parse(this.state.query || "");

        if (!parsedQuery) {
            console.error("Couldn't parse query document");
            return null;
        }

        var token = cm.getTokenAt(mousePos);
        var start = {line: mousePos.line, ch: token.start};
        var end = {line: mousePos.line, ch: token.end};
        var relevantMousePos = {
            start: cm.indexFromPos(start),
            end: cm.indexFromPos(end)
        };

        var position = relevantMousePos;

        var def = parsedQuery.definitions.find(definition => {
            if (!definition.loc) {
                console.log("Missing location information for definition");
                return false;
            }

            const {start, end} = definition.loc;
            return start <= position.start && end >= position.end;
        });

        if (!def) {
            console.error(
                "Unable to find definition corresponding to mouse position"
            );
            return null;
        }

        var operationKind =
            def.kind === "OperationDefinition"
                ? def.operation
                : def.kind === "FragmentDefinition"
                ? "fragment"
                : "unknown";

        var operationName =
            def.kind === "OperationDefinition" && !!def.name
                ? def.name.value
                : def.kind === "FragmentDefinition" && !!def.name
                ? def.name.value
                : "unknown";

        var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

        var el = document.querySelector(selector);
        el && el.scrollIntoView();
    };

    _handleEditQuery = (query) => this.setState({query});

    _handleToggleExplorer = () => {
        this.setState({explorerIsOpen: !this.state.explorerIsOpen});
    };

    constructor(props) {
        super(props);
        pageId = this.props.pageId;
        schemaUrl = this.props.schemaUrl;
        // requestHeaders = this.props.requestHeaders;
        defaultQueries = this.props.defaultQueries;
        // alert(JSON.stringify(this.props, null, 2))
    }

    render() {

        schemaUrl = this.props.schemaUrl;
        let {query, schema} = this.state;

        let windowExists = exists(window)
        if (windowExists) {
            return (

                <>
                <Responsive {...Responsive.onlyMobile}>
                    <div className="ui icon message info">
                        <i className="desktop icon"></i>
                        <div className="content">
                            <div className="mini">
                                On mobile the explorer is not well displayed! Try landscape mode or use a bigger device!
                            </div>
                        </div>
                    </div>
                    <br/>
                </Responsive>

                <div className="graphiql-container">

                    <GraphiQLExplorer
                        schema={schema}
                        query={query}
                        onEdit={this._handleEditQuery}
                        onRunOperation={operationName =>
                            this._graphiql.handleRunQuery(operationName)
                        }
                        explorerIsOpen={this.state.explorerIsOpen}
                        onToggleExplorer={this._handleToggleExplorer}
                        getDefaultScalarArgValue={getDefaultScalarArgValue}
                        makeDefaultArg={makeDefaultArg}
                    />
                    <GraphiQL
                        ref={ref => (this._graphiql = ref)}
                        fetcher={fetcher}
                        schema={schema}
                        query={query}
                        defaultQuery={defaultQueries}
                        editorTheme="material"
                        onEditQuery={this._handleEditQuery}
                    >
                        <GraphiQL.Logo>
                            GraphiQL
                        </GraphiQL.Logo>
                        <GraphiQL.Toolbar>
                            <GraphiQL.Button
                                onClick={() => this._graphiql.handlePrettifyQuery()}
                                label="Prettify"
                                title="Prettify Query (Shift-Ctrl-P)"
                            />
                            <GraphiQL.Button
                                onClick={() => this._graphiql.handleToggleHistory()}
                                label="History"
                                title="Show History"
                            />
                            <GraphiQL.Button
                                onClick={this._handleToggleExplorer}
                                label="Explorer"
                                title="Toggle Explorer"
                            />

                        </GraphiQL.Toolbar>
                        <GraphiQL.Footer>
                                <i className="mobile icon"></i> <strong>{schemaUrl}</strong>
                        </GraphiQL.Footer>
                    </GraphiQL>
                </div>
                <br/>
                <form className="ui form">
                    <h4 className="ui dividing header">GRAPHQL API DETAILS</h4>

                    <div className="fields">
                        <div className="twelve wide field disabled">
                            <label>API URL</label>
                            <input type="text" name="api url" maxLength="16" placeholder="url" value={schemaUrl}/>
                        </div>
                        <div className="four wide field disabled">
                            <label>API KEY</label>
                            <input type="text" name="api key" maxLength="4" placeholder="key" value={JSON.stringify(requestHeaders)}/>
                        </div>

                    </div>
                </form>
                </>
            );
        } else
            return (<></>)
    }
}

export default GraphqlDashboard;




// ############## from CustomArgs.js

function unwrapOutputType(outputType) {
    let unwrappedType = outputType;
    while (isWrappingType(unwrappedType)) {
        unwrappedType = unwrappedType.ofType;
    }
    return unwrappedType;
}

function makeDefaultArg(
    parentField,
    arg
) {
    const unwrappedType = unwrapOutputType(parentField.type);
    if (
        unwrappedType.name.startsWith("GitHub") &&
        unwrappedType.name.endsWith("Connection") &&
        (arg.name === "first" || arg.name === "orderBy")
    ) {
        return true;
    }
    return false;
}

function getDefaultScalarArgValue(
    parentField,
    arg,
    argType
) {
    const unwrappedType = unwrapOutputType(parentField.type);
    switch (unwrappedType.name) {
        case "GitHubRepository":
            if (arg.name === "name") {
                return {kind: "StringValue", value: "graphql-js"};
            } else if (arg.name === "owner") {
                return {kind: "StringValue", value: "graphql"};
            }
            break;
        case "NpmPackage":
            if (arg.name === "name") {
                return {kind: "StringValue", value: "graphql"};
            }
            break;
        default:
            if (
                isEnumType(argType) &&
                unwrappedType.name.startsWith("GitHub") &&
                unwrappedType.name.endsWith("Connection")
            ) {
                if (
                    arg.name === "direction" &&
                    argType
                        .getValues()
                        .map(x => x.name)
                        .includes("DESC")
                ) {
                    return {kind: "EnumValue", value: "DESC"};
                } else if (
                    arg.name === "field" &&
                    argType
                        .getValues()
                        .map(x => x.name)
                        .includes("CREATED_AT")
                ) {
                    return {kind: "EnumValue", value: "CREATED_AT"};
                }
            }
            return GraphiQLExplorer.defaultValue(argType);
    }
}