import React from "react";

import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import GraphqlDashboard from "../components/GraphqlDashboard/GraphqlDashboard";
import Image from '@bit/semantic-org.semantic-ui-react.image'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import { graphql, Link } from 'gatsby'


function getDefaultQueriesData(localUrl) {
    return new Promise((resolve, reject) => {
        // create a new XMLHttpRequest
        let xhr = new XMLHttpRequest()
        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the state of the component with the result here
            console.log(xhr.status)
        })
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject(xhr.statusText);
            }
        };
        xhr.onerror = () => reject(xhr.statusText);
        // open the request with the verb and the url
        xhr.open('GET', localUrl)
        // send the request
        xhr.send()
    })
}

export default ({ data }) => {
    // alert(JSON.stringify(data, null, 2))

    const path = require('../../content/apis/implemented/logos/' + data.implementedApisJson.image);

    let disqusConfig = {
        url: "https://apifacade.com/"  + data.implementedApisJson.id,
        "identifier": data.implementedApisJson.id,
        title: data.implementedApisJson.name
    }

    getDefaultQueriesData("/queries/" + data.implementedApisJson.id + ".txt").then(requestData => {
            // alert(requestData)
            // treating the case when no file in static (Gatsby is generating a page with status 200)
            if(!requestData.startsWith("<!DOCTYPE")) {
                data.implementedApisJson.defaultQueries = requestData
            } else {
                data.implementedApisJson.defaultQueries = ""
            }
        }
    )

    return (
        <Layout>
            <SEO description={data.implementedApisJson.description} title={data.implementedApisJson.name}>
            </SEO>
            <Section>
                <div style={{ marginTop: "50px" }}>
                    <div className="ui grid stackable">
                        <div className="row">
                            <div className="one wide column">
                                <Image floated='left' size='tiny' alt={data.implementedApisJson.name} src={path}/>
                            </div>
                            <div className="six wide column">
                                <Headings.h1>{data.implementedApisJson.name}</Headings.h1>
                            </div>
                            <div className="three wide column">
                                By: <a href={data.implementedApisJson.providerAddress}><strong>{data.implementedApisJson.provider}</strong></a> | Category: <strong>{data.implementedApisJson.category}</strong>
                                &nbsp;<a className="ui teal tag label">{data.implementedApisJson.payment}</a>
                            </div>
                            <div className="six wide column">

                                <div className="ui statistics small">
                                    <div className="statistic">
                                        <div className="value">
                                            {data.implementedApisJson.popularity}/10
                                        </div>
                                        <div className="label">
                                            Pupularity
                                        </div>
                                    </div>
                                    <div className="statistic">
                                        <div className="value">
                                            {data.implementedApisJson.latency}ms
                                        </div>
                                        <div className="label">
                                            Latency
                                        </div>
                                    </div>
                                    <div className="statistic">
                                        <div className="value">
                                            <i className="plane icon"></i>
                                            {data.implementedApisJson.success}%
                                        </div>
                                        <div className="label">
                                            Success
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="sixteen wide column">
                                <h2>{data.implementedApisJson.description}</h2>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <GraphqlDashboard schemaUrl={data.implementedApisJson.schemaUrl}
                                      pageId={data.implementedApisJson.id}
                                      defaultQueries={data.implementedApisJson.defaultQueries}
                                      requestHeaders={data.implementedApisJson.requestHeaders}
                    />
                </div>

                {/*<CommentCount config={disqusConfig} placeholder={'...'} />*/}
                <Disqus config={disqusConfig} />
            </Section>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        implementedApisJson(id: {eq: $slug}) {
            name
            description
            followers
            id
            image
            providerAddress
            payment
            score
            category,
            schemaUrl,
            defaultQueries,
            provider,
            popularity,
            latency,
            success,
            requestHeaders {
                x_api_key
            }
        }
    }
`